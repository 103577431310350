<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0" style="overflow-y:hidden;">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular
            indeterminate
            size="64"
            :color="color.theme"
          ></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>
        <v-overlay :value="loadrecheckimap"></v-overlay>

        <!-- hearder -->
        <v-list nav :color="color.BG" v-if="checkserviceimap === true">
          <v-list-item class="mb-n1">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-email-newsletter</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage"
                  >&nbsp;{{ $t("admintoolbar.recheckimap") }}</span
                >
              </v-list-item-title>
            </v-list-item-content>
            <v-spacer v-if="resolutionScreen >= 400"></v-spacer>
            <v-card v-if="resolutionScreen >= 400" width="320" outlined>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content class="mr-n4">
                    <v-list-item-subtitle class="caption font-weight-light">
                      <v-layout row wrap class="mx-6">
                        <v-flex lg12>
                          <h4 :style="'color:' + color.theme">
                            <v-icon :color="color.theme" class=""
                              >mdi-mail</v-icon
                            >
                            {{ $t("adminrecheckimap.storagemailautokeep") }}
                          </h4>
                          <v-progress-linear
                            :color="percentagestoagecitizeninbiz_setcolor_"
                            rounded
                            :value="percentageStoragecitizeninbiz === -1 ? 0 : percentageStoragecitizeninbiz"
                            height="12"
                          >
                            <span v-if="percentageStoragecitizeninbiz === -1" style="color: #174996; font-size: 15px;" class="mt-n1">ထ</span>
                            <span v-else :style="'color: ' + percentagestoagecitizeninbiz_setcolor_ +  '; font-size: 10px;'">{{percentageStoragecitizeninbiz}} %</span>
                          </v-progress-linear>
                          <strong class="caption font-weight-light">
                           {{ emailimap.usage_storage }} {{$t("adminrecheckimap.in")}} {{ percentageStoragecitizeninbiz === -1 ? $t("adminrecheckimap.unlimit") : emailimap.storage_mail }}
                          </strong>
                        </v-flex>
                      </v-layout>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-list-item>
        </v-list>
        <v-card v-if="resolutionScreen < 400" outlined class="mx-4">
          <v-list dense>
            <v-list-item>
              <v-list-item-content class="mr-n4">
                <v-list-item-subtitle class="caption font-weight-light">
                  <v-layout row wrap class="mx-6">
                    <v-flex lg12>
                      <h4 :style="'color:' + color.theme">
                        <v-icon :color="color.theme" class=""
                          >mdi-mail</v-icon
                        >
                        {{ $t("adminrecheckimap.storagemailautokeep") }}
                      </h4>
                      <v-progress-linear
                        :color="percentagestoagecitizeninbiz_setcolor_"
                        rounded
                        :value="percentageStoragecitizeninbiz === -1 ? 100 : percentageStoragecitizeninbiz"
                        height="12"
                      >
                        <span v-if="percentageStoragecitizeninbiz === -1" style="color: #174996; font-size: 15px;" class="mt-n1">ထ</span>
                        <span v-else :style="'color: ' + percentagestoagecitizeninbiz_setcolor_ +  '; font-size: 10px;'">{{percentageStoragecitizeninbiz}} %</span>
                      </v-progress-linear>
                      <strong class="caption font-weight-light">
                       {{ emailimap.usage_storage }} {{$t("adminrecheckimap.in")}} {{ percentageStoragecitizeninbiz === -1 ? $t("adminrecheckimap.unlimit") : emailimap.storage_mail }}
                      </strong>
                    </v-flex>
                  </v-layout>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card
          class="elevation-0"
          :color="color.BG"
        >
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout row wrap justify-center fill-height>
                <v-flex
                  lg12
                  class="ma-2 ml-4 mr-4"
                  v-if="loaddataprogress === true"
                >
                  <div class="text-center">
                    <v-progress-circular
                      :size="50"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <div>
                    <!-- Service IMAP -->
                    <div v-if="checkserviceimap === true">
                      <v-card class="mx-auto px-4 pt-6" max-width="850">
                        <v-card-text>
                          <v-layout row warp>
                            <!-- Email -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-select
                                :items="listemailimap"
                                :label="$t('email')"
                                v-model="emailimap"
                                item-value="business_email"
                                item-text="business_email"
                                outlined
                                dense
                                prepend-icon="mdi-email"
                                class="pa-1 pt-0 pb-0"
                                return-object
                              >
                                <template :color="color.theme" v-slot:item="{ item }">
                                  <span style="">{{ item.business_email }}</span>
                                </template>
                              </v-select>
                            </v-flex>
                            <!-- Password -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-text-field
                                :label="$t('password')"
                                prepend-icon="lock"
                                outlined
                                color="#174966"
                                dense
                                v-model.trim="password"
                                @click:append="showpassword = !showpassword"
                                :type="showpassword ? 'text' : 'password'"
                                :append-icon="
                                  showpassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                class="pa-1 pt-0 pb-0"
                                :error-messages="passwordError"
                                required
                                @input="$v.password.$touch()"
                                @blur="$v.password.$touch()"
                              ></v-text-field>
                            </v-flex>
                            <!-- Date Start -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-dialog
                                ref="dialogFromdateupload"
                                v-model="modalFromdateupload"
                                :return-value.sync="fromdateupload"
                                persistent
                                full-width
                                width="290px"
                                :color="color.theme"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    clearable
                                    :color="color.theme"
                                    :label="
                                      $t(
                                        'adminrecheckimap.dateemail'
                                      )
                                    "
                                    v-model="fromdateupload"
                                    @click:prepend="modalFromdateupload = true"
                                    prepend-icon="event"
                                    v-on="on"
                                    readonly
                                    outlined
                                    dense
                                    :error-messages="fromdateuploadError"
                                    required
                                    @input="$v.fromdateupload.$touch()"
                                    @blur="$v.fromdateupload.$touch()"
                                    @click:clear="todateupload = ''"
                                    class="my-hint-style pa-1 pt-0 pb-0"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="fromdateupload"
                                  scrollable
                                  :locale="$t('default')"
                                  @input="changeFromdateupload()"
                                  :color="color.theme"
                                  :max="datenow"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="error"
                                    @click.stop="modalFromdateupload = false"
                                    >{{
                                      $t(
                                        "myinboxPage.conditionOptional.closefromdateupload"
                                      )
                                    }}</v-btn
                                  >
                                </v-date-picker>
                              </v-dialog>
                            </v-flex>
                            <!-- Date End -->
                            <v-flex xs12 sm12 md6 lg6>
                              <v-dialog
                                ref="dialogTodateupload"
                                v-model="modalTodateupload"
                                :return-value.sync="todateupload"
                                persistent
                                full-width
                                width="290px"
                                :color="color.theme"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    :disabled="!fromdateupload"
                                    clearable
                                    readonly
                                    :color="color.theme"
                                    :label="
                                      $t(
                                        'myinboxPage.conditionOptional.todateupload'
                                      )
                                    "
                                    v-model="todateupload"
                                    prepend-icon="event"
                                    @click:prepend="modalTodateupload = true"
                                    v-on="on"
                                    :error-messages="todateuploadError"
                                    required
                                    @input="$v.todateupload.$touch()"
                                    @blur="$v.todateupload.$touch()"
                                    outlined
                                    dense
                                    class="my-hint-style pa-1 pt-0 pb-0"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="todateupload"
                                  scrollable
                                  :locale="$t('default')"
                                  @input="
                                    $refs.dialogTodateupload.save(todateupload)
                                  "
                                  :max="datemonth"
                                  :min="fromdateupload"
                                  :color="color.theme"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="error"
                                    @click.stop="modalTodateupload = false"
                                    >{{
                                      $t(
                                        "myinboxPage.conditionOptional.closetodateupload"
                                      )
                                    }}</v-btn
                                  >
                                </v-date-picker>
                              </v-dialog>
                            </v-flex>
                          </v-layout>
                        </v-card-text>
                      </v-card>
                      <div class="text-center mt-3">
                        <v-btn
                          @click="recheckIMAP()"
                          :color="color.theme"
                          class="white--text"
                          :loading="loadrecheckimap"
                          :disabled="dataReady"
                        >
                          {{ $t("adminrecheckimap.checkimapbutton") }}
                        </v-btn>
                      </div>
                    </div>
                    <!-- No Service IMAP -->
                    <div v-else>
                      <v-card class="mx-auto py-6 pl-3" max-width="600">
                        <v-card-title>
                          {{ $t("adminrecheckimap.noserviceimap") }}
                        </v-card-title>
                      </v-card>
                    </div>
                    <br />
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card>
      <dialogsnackbardownloadrecheckimap
        :show="opensnackbardownloadrecheckimap"
        :listrecheckdownload="listrecheckdownload"
        @closedialog="opensnackbardownloadrecheckimap = false"
      />
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import gb_sizeDataConverter from "../globalFunctions/sizeDataConverter";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
import format from "date-fns/format";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
const dialogsnackbardownloadrecheckimap = () =>
  import("../components/optional/dialog-snackbardownloadrecheckimap.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    fromdateupload: { required },
    password: { required },
    todateupload: { required },
  },
  components: {
    onebox_admin_toolbar,
    dialogsnackbardownloadrecheckimap,
  },
  data: function() {
    return {
      opensnackbardownloadrecheckimap: false,
      loaddataprogress: true,
      listemailimap: [],
      emailimap: {},
      checkserviceimap: false,
      password: "",
      showpassword: false,
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      datenow: "",
      datemonth: "",
      listrecheckdownload: {
        status: "",
        allcheckemail: 0,
        nowemail: 0,
        timeout: -1,
        percen: 0,
      },
      loadrecheckimap: false,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    // ตัวเช็คการกรอกข้อมูลครบถ้วนหรือไม่
    dataReady() {
      if (
        this.emailimap !== "" &&
        this.password !== "" &&
        this.fromdateupload !== "" &&
        this.todateupload !== ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    fromdateuploadError() {
      const errors = [];
      if (!this.$v.fromdateupload.$dirty) return errors;
      !this.$v.fromdateupload.required &&
        errors.push(this.$t("adminrecheckimap.pleaseselectdate"));
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(this.$t("adminrecheckimap.pleaseenterpasswordemail"));
      return errors;
    },
    todateuploadError() {
      const errors = [];
      if (!this.$v.todateupload.$dirty) return errors;
      !this.$v.todateupload.required &&
        errors.push(this.$t("adminrecheckimap.pleaseselectdate"));
      return errors;
    },
    percentageStoragecitizeninbiz() {
      if (this.emailimap.storage_mail === "0.00 KB") {
        console.log("this.emailimap.storage_mail", this.emailimap.storage_mail);
        return -1;
      } else {
        let usage_storage = gb_sizeDataConverter.convertStorageToByte(this.emailimap.usage_storage);
        let storage_mail = gb_sizeDataConverter.convertStorageToByte(this.emailimap.storage_mail);
        let percent = (usage_storage*100) / storage_mail
        return Math.ceil(percent);
      }
    },
    percentagestoagecitizeninbiz_setcolor_() {
      if (
        this.percentageStoragecitizeninbiz === -1
      ) {
        console.log("primary", this.emailimap.storage_mail);
        return "primary";
      } else if (
        Math.ceil(this.percentageStoragecitizeninbiz) >= 0 &&
        Math.ceil(this.percentageStoragecitizeninbiz) < 70
      ) {
        // return this.color.theme;
        return "green";
      } else if (
        Math.ceil(this.percentageStoragecitizeninbiz) >= 70 &&
        Math.ceil(this.percentageStoragecitizeninbiz) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragecitizeninbiz) >= 90) {
        return "red";
      }
    },
  },
  methods: {
    // จัดการวันที่
    // เมื่อเลือกเดือนไหนให้บวกวันไปตามจำนวนวันในเดือนนั้นๆ โดยไม่เกินวันปัจจุบัน
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = this.formatDate(this.fromdateupload);

      // จำนวนวันของเดือน
      let nummonth = new Date(
        this.fromdateupload.slice(0, 4),
        this.fromdateupload.slice(5, 7),
        0
      ).getDate();

      // วันที่เลือก
      let dateselect = new Date(this.fromdateupload);

      // วันปัจจุบัน
      let date = new Date();

      // วันที่สิ้นสุดการเช็ค
      this.datemonth = new Date(
        dateselect.setDate(dateselect.getDate() + (nummonth - 1))
      );
      if (this.datemonth > date) {
        this.datemonth = format(date, "yyyy-MM-dd");
      } else {
        this.datemonth = format(this.datemonth, "yyyy-MM-dd");
      }
    },
    formatDate(datefrom) {
      var dateNow = new Date();
      var date = new Date(datefrom);
      console.log("datefrom", datefrom);
      if (datefrom){
        let nummonth = new Date(
          this.fromdateupload.slice(0, 4),
          this.fromdateupload.slice(5, 7),
          0
        ).getDate();
        date.setDate(date.getDate() + nummonth - 1);
        if (date > dateNow){
          let todate = new Date().toISOString().substr(0, 10);
          const [year, month, day] = todate.split("-");
          return `${year}-${month}-${day}`;
        }else{
          let todate = new Date(date).toISOString().substr(0, 10);
          const [year, month, day] = todate.split("-");
          return `${year}-${month}-${day}`;
        }
      }
    },
    // เช็ค Service IMAP และ get email address ของ business นั้นๆ
    async getCheckEmailIMAP() {
      let payload = {
        // business_id: "faad7c61db52b7d1de4f1a6fc202f33f",
        business_id: this.dataAccountActive.business_info.business_id,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_IMAP + "/api/imap/check_email_imap",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((res) => {
          if (res.data.status === "OK") {
            this.checkserviceimap = true;
            this.listemailimap = res.data.result;
            this.emailimap = this.listemailimap[0];
          } else if (res.data.errorCode === "ER104") {
            this.checkserviceimap = false;
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
          this.loaddataprogress = false;
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loaddataprogress = false;
        });
    },
    // เช็คและ get email ทั้งหมดในช่วงเวลาที่เลือก
    async recheckIMAP() {
      this.clearData();
      this.loadrecheckimap = true;
      this.opensnackbardownloadrecheckimap = true;
      this.listrecheckdownload.status = "start";
      // รูปแบบวันที่ต้องส่งไปคือ dd-MM-yyyy
      let since_date =
        this.fromdateupload.slice(8, 10) +
        this.fromdateupload.slice(4, 8) +
        this.fromdateupload.slice(0, 4);
      let before_date =
        this.todateupload.slice(8, 10) +
        this.todateupload.slice(4, 8) +
        this.todateupload.slice(0, 4);
      let payload = {
        // mail_user: "hcds@etax.one.th",
        // mail_pass: "Mail@2020",
        mail_user: this.emailimap.business_email,
        mail_pass: this.password,
        since_date: since_date,
        before_date: before_date,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_IMAP + "/api/imap/download_attachments",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((res) => {
          if (res.data.status === "OK") {
            this.checkDocEmailIMAP(res.data);
          } else if (res.data.errorCode === "ER104") {
            Toast.fire({
              icon: "error",
              title: this.$t("checkpassword.passwordincorrect"),
            });
            this.clearData();
          } else if (res.data.errorCode === "ER204") {
            Toast.fire({
              icon: "error",
              title: this.$t("adminrecheckimap.noemailsavedoc"),
            });
            this.clearData();
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.clearData();
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.clearData();
        });
    },
    // เช็คเอกสารในแต่ละ email
    async checkDocEmailIMAP(data) {
      let auth = await gbfGenerate.generateToken();
      this.listrecheckdownload.allcheckemail = data.total_email;
      this.listrecheckdownload.status = "checkallemail";
      for (let i = 0; data.total_email > i; i++) {
        let payload = data.file_lists[i];
        payload.process_id = data.process_id;
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_IMAP + "/api/imap/save_header_info",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((res) => {
            if (res.data.status === "OK") {
              this.listrecheckdownload.nowemail = i;
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("toast.cannotconnectonebox"),
              });
              this.clearData();
            }
          })
          .catch((err) => {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.clearData();
          });
        if (data.total_email === i + 1) {
          this.listrecheckdownload.status = "download";
          this.downloadCheckDocIMAP(data.process_id);
        }
      }
    },
    // download ไฟล์สำหรับสถานะการบันทึกเอกสารแต่ละ email
    async downloadCheckDocIMAP(process_id) {
      let payload = {
        process_id: process_id,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_IMAP + "/api/imap/check_inbox_status",
          payload,
          {
            headers: { Authorization: auth.code },
            withCredentials: false,
            responseType: "arraybuffer",
            onDownloadProgress: (progressEvent) => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              if (progresspercent >= 95) {
                this.listrecheckdownload.percen = 95;
              } else {
                this.listrecheckdownload.percen = progresspercent;
              }
            },
          }
        )
        .then((response) => {
          if (response.headers["content-type"] === "application/json") {
            Toast.fire({
              icon: "error",
              title: this.$t("adminrecheckimap.notdownload"),
            });
            this.clearData();
          } else {
            this.listrecheckdownload.percen = 100;
            let headers = response.headers;
            let blob = new Blob([response.data], {
              type: headers["content-type"],
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute(
              "download",
              "IMAP_" + format(new Date(), "yyyyMMddHHmmss") + ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
            this.listrecheckdownload.timeout = 3000;
            this.loadrecheckimap = false;
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.clearData();
        });
    },
    clearData() {
      this.listrecheckdownload = {
        status: "",
        allcheckemail: 0,
        nowemail: 0,
        timeout: -1,
        percen: 0,
      };
      this.loadrecheckimap = false;
      this.opensnackbardownloadrecheckimap = false;
    },
  },
  mounted() {
    this.getCheckEmailIMAP();
  },
  created() {
    //วันปัจจุบันจัด format แล้ว
    this.datenow = format(new Date(), "yyyy-MM-dd");
  },
};
</script>
<style>
/* #thiscontainersetemailadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
} */
</style>
